<script setup>
import { ref } from "vue";
import FhButton from "@/components/FhButton.vue";
import { CREDIT_CARDS } from "@/constants";

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  cardType: {
    type: String,
    default: null
  },
  nickName: {
    type: String,
    default: null
  },
  lastFour: {
    type: String,
    required: true
  },
  expiration: {
    type: String,
    required: true
  },
  showRemoveLink: {
    type: Boolean,
    default: true
  },
  isExpired: {
    type: Boolean,
    default: false
  }
});

const paymentCardRef = ref(null);

const emit = defineEmits(["delete"]);

const handleRemoveClick = (event) => {
  event.preventDefault();
  emit("delete", props.id);
};

const dateSlice = props?.expiration?.split("/");
const card_icon = Object.values(CREDIT_CARDS).find((card) => card.mapName === props.cardType)?.icon;

const AriaClickText = `Remove credit card ending in ${props.lastFour}`;

defineExpose({
  paymentCardRef
});
</script>

<template>
  <div ref="paymentCardRef" class="paymentCard flex flex-col gap-f3">
    <img v-if="card_icon" class="paymentCard__icon block w-16 md:w-20" :src="card_icon" alt="Credit Card Vendor Icon" />
    <div>
      <h4 class="text-body-lg paymentCard__name mb-1">{{ props.nickName }}</h4>
      <div class="paymentCard__details flex gap-1">
        <span class="text-neutral-50">Ending in {{ props.lastFour }}</span>
        <span class="paymentCard__detailsDivider text-neutral-50">&#8226;</span>
        <span v-if="dateSlice" class="text-neutral-50" :class="{ 'text-brand-red': isExpired }">
          Exp {{ String(dateSlice[0]).padStart(2, "0") }}/{{ String(dateSlice[1]).slice(2) }}
        </span>
        <span v-if="showRemoveLink" class="ml-4">
          <FhButton variant="link" :aria-label="AriaClickText" @click="handleRemoveClick">Remove</FhButton>
        </span>
      </div>
    </div>
  </div>
</template>
