<template>
  <FhSection
    class="fhSlideshowSection"
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="fhSlideshow">
      <swiper :modules="[Navigation, Scrollbar]" :slides-per-view="1" :scrollbar="{ draggable: true }" navigation>
        <swiper-slide v-for="slide in slides" :key="slide" :class="slide.hasOverlay ? 'filter' : ''">
          <FhFigure v-if="!slide.isVideo" :data="slide" />
          <FhFigure v-else :data="slide" is-video="slide.isVideo" />
        </swiper-slide>
      </swiper>
    </div>
  </FhSection>
</template>

<script setup>
import { getDataRefs } from "@/util/vue";

import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import FhFigure from "@/components/FhFigure.vue";
import FhSection from "@/includes/FhSection.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true,
    default: () => ({})
  }
});

const { slides } = getDataRefs(props.data);
</script>

<style lang="pcss" scoped>
.fhSlideshowSection.widget--width-md,
.fhSlideshowSection.widget--width-sm,
.fhSlideshowSection.widget--width-md:before,
.fhSlideshowSection.widget--width-sm:before,
.fhSlideshowSection.widget--width-md:after,
.fhSlideshowSection.widget--width-sm:after {
  --1vw: 0px;
}
.fhSlideshowSection:not(.widget--width-full)::before {
  width: auto;
}

.fhSlideshowSection[class*="slate"] .swiper {
  @apply bg-slate;
}
.fhSlideshowSection[class*="umber"] .swiper {
  @apply bg-umber;
}
.fhSlideshowSection[class*="limestone"] .swiper {
  @apply bg-limestone;
}
.fhSlideshowSection[class*="sage"] .swiper {
  @apply bg-sage;
}

.fhSlideshow {
  &:deep(.swiper) {
    @apply pb-[80px];
  }
  &:deep(.swiper-slide) {
    @apply h-auto;
  }
  &:deep(.swiper-button-prev),
  &:deep(.swiper-button-next) {
    @apply hidden h-[20px] w-[20px] md:flex right-0 bottom-[30px];
    top: auto;
  }
  &:deep(.swiper-button-prev) {
    @apply left-auto right-[10%] bg-contain bg-center bg-no-repeat;

    background-image: url("../assets/icons/icon-arrow-prev.svg");
  }
  &:deep(.swiper-button-next) {
    @apply bg-contain  right-[5%] bg-center bg-no-repeat;
    background-image: url("../assets/icons/icon-arrow-next.svg");
  }
  &:deep(.swiper-button-prev::after),
  &:deep(.swiper-button-next::after) {
    @apply hidden;
  }

  &:deep(.swiper-scrollbar) {
    @apply left-0 md:left-f4 bottom-[32px] w-full md:w-[80%];
    height: 17px;
    border-radius: 0;

    background: transparent;
    &::before {
      @apply bg-neutral-30;
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      right: 0;
      bottom: 8px;
      height: 2px;
    }
  }

  .widget--width-sm &:deep(.swiper-scrollbar) {
    @apply md:w-[80%];
  }

  .widget--width-md &:deep(.swiper-scrollbar) {
    @apply md:w-[80%];
  }

  &:deep(.swiper-scrollbar-drag) {
    @apply bg-slate;
    cursor: pointer;
    height: 2px;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-radius: 0;
    background-clip: content-box;
    box-sizing: content-box;
  }

  &:deep(figure) {
    @apply absolute left-0 right-0 top-0 bottom-0 h-full w-full object-cover overflow-hidden object-center;
  }

  &:deep(section),
  &:deep(figure img),
  &:deep(section .widget__inner),
  &:deep(section .fh-figure) {
    @apply h-full w-full object-cover object-center;
  }
}

.video_block {
  @apply static;
}

:deep(.filter .fh-figure > div:before) {
  @apply bg-neutral-opacity-35;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  z-index: -1;
}

/** SLATE CONTROLS **/
.widget--bg-slate :deep(.fhSlideshow .swiper-scrollbar-drag) {
  @apply bg-white-opacity-70;
}
.widget--bg-slate :deep(.fhSlideshow .swiper-scrollbar::before) {
  @apply bg-neutral-opacity-80;
}
.widget--bg-slate :deep(.swiper-button-prev) {
  @apply left-auto bg-contain bg-center bg-no-repeat;
  filter: invert(1);
  background-image: url("../assets/icons/icon-arrow-prev.svg");
}
.widget--bg-slate :deep(.swiper-button-next) {
  @apply bg-contain bg-center bg-no-repeat;
  filter: invert(1);
  background-image: url("../assets/icons/icon-arrow-next.svg");
}
/** END SLATE CONTROLS **/
</style>
