<script setup>
import { computed, ref, watch } from "vue";
import { useViewportSizes } from "@/composables";
import FhProductFilterBarItem from "@/components/FhProductFilterBarItem.vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhProductFilterNav from "@/components/Products/FhProductFilterNav/FhProductFilterNav.vue";
import FhProductFilterChips from "@/components/Products/FhProductFilterChips.vue";
import FhSortFilter from "@/components/Partials/FhSortFilter/FhSortFilter.vue";
import { useProductFiltersStore } from "@/stores/ProductFiltersStore";
import { useFeaturesStore } from "@/stores/FeaturesStore";

const props = defineProps({
  filters: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Array,
    required: true,
    default: () => []
  },
  sortOptions: {
    type: Array,
    default: () => []
  },
  sortOrder: {
    type: String,
    default: ""
  },
  totalProductsCount: {
    type: Number,
    default: 0
  },
  facetCounts: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(["update:modelValue", "update:sortValue"]);

const { isSm, isMd } = useViewportSizes();
const openAllFilters = ref(false);
const productFilterStore = useProductFiltersStore();
const { setURLParam, updateURL, clearAllParams, deleteSearchParam } = productFilterStore.urlState;
const { init: initFilterStore, updateFilterNameMap } = productFilterStore;
const facetCounts = computed(() => props.facetCounts);

const sortModel = ref(props.sortOrder);

const featuresStore = useFeaturesStore();
const projectEmberEnabled = computed(() => featuresStore.getFeatureValue("projectEmberEnabled"));

initFilterStore(props.filters);

const updateSearchURL = (model) => {
  Object.entries(model).forEach(([key, value]) => {
    if (value.length > 0) {
      setURLParam(key, value.join(","));
    } else {
      deleteSearchParam(key);
    }
  });
  deleteSearchParam("page");
  updateURL();
};

/**
 * This writable computed is the primary data model, representing the full set of filters and selected options for each.
 * Each filter has a key in this object, pointing to an array of the selected options for that filter. For example:
 *
 *   {
 *     filterKey1: ["option1"],
 *     filterKey2: []
 *   }
 *
 * Selected options are automatically populated from the inbound `modelValue` prop.
 *
 * The public model published via the `update:modelValue` event only contains keys for active filters (i.e., those with selected options).
 * TODO: we will probalby want to change this to match up what we are receving and sending, keeping object schema as is for now and doing transformations downstream
 */
const model = computed({
  get: () => {
    return props.filters.reduce((obj, filter) => {
      const activeFilters = props.modelValue?.find((activeFilter) => activeFilter.key === filter.key);
      obj[filter.key] = activeFilters ? activeFilters.values : [];
      return obj;
    }, {});
  },
  set: (newModel) => {
    let modelToPublish = Object.keys(newModel)
      .filter((key) => newModel[key].length > 0)
      .reduce((obj, key) => ({ ...obj, [key]: newModel[key] }), {});
    updateSearchURL(newModel);
    emit("update:modelValue", modelToPublish);
  }
});

const totalActiveFilterOptionCount = computed(() => {
  return Object.keys(model.value).reduce((count, key) => {
    const filter = Object.values(props.filters).find((filter) => filter.key === key);
    if (filter.type === "range" && model.value[key].length > 0) {
      return count + 1;
    }
    return count + model.value[key].length;
  }, 0);
});

const clearAll = () => {
  model.value = Object.keys(model.value).reduce((obj, key) => ({ ...obj, [key]: [] }), {});
  clearAllParams();
};

const handleOpenAllFilters = (event) => {
  if (event) event.preventDefault();
  openAllFilters.value = !openAllFilters.value;
};

const getFacetCountByKey = (filterKey) => {
  const facet = Object.values(props.facetCounts).find((facet) => facet.key === filterKey);

  return facet;
};

watch(facetCounts, () => {
  updateFilterNameMap(facetCounts.value);
});
watch(sortModel, (newValue) => {
  emit("update:sortValue", newValue);
});
</script>

<template>
  <div class="c-productFilterBar sticky top-0 z-10 flex justify-between items-center border-b border-neutral-30 bg-white py-f4">
    <div class="flex items-center">
      <template v-for="(filter, index) in props.filters">
        <FhProductFilterBarItem
          v-if="index < (projectEmberEnabled ? 5 : 4)"
          :key="filter.key"
          v-bind="filter"
          :model-value="model[filter.key]"
          :facet-counts="getFacetCountByKey(filter.key)"
          class="mr-f2 hidden lg:block"
          @update:model-value="(val) => (model = { ...model, [filter.key]: val })"
        />
      </template>

      <FhButton
        v-if="props.filters.length > 0"
        class="allFilter__cta"
        variant="pill"
        :color="isSm || isMd ? 'primary-inverse' : 'primary'"
        @click="handleOpenAllFilters"
      >
        <FhIcon name="List" />
        <span class="hidden sort-bp:block">All Filters</span>
        <span class="sort-bp:hidden">Sort & Filter</span>
        <span v-if="totalActiveFilterOptionCount > 0">({{ totalActiveFilterOptionCount }})</span>
      </FhButton>
      <FhButton v-if="totalActiveFilterOptionCount > 0" variant="text" class="ml-f3 hidden lg:block" @click="clearAll">Clear All</FhButton>
    </div>
    <div class="flex flex-row gap-7">
      <FhSortFilter
        v-if="props.sortOptions.length > 0"
        v-model="sortModel"
        :sort-options="props.sortOptions"
        class="w-58 hidden sort-bp:block"
        @update:model-value="(val) => (sortModel = val)"
      />
      <div v-if="props.totalProductsCount > 0" class="ml-auto">{{ props.totalProductsCount }} items</div>
    </div>
  </div>
  <FhProductFilterChips
    class="border-b-0 md:pt-10 md:pl-0"
    :filters="props.filters"
    :filter-model="model"
    :facet-counts="props.facetCounts"
    @update:model-value="(val) => (model = val)"
  ></FhProductFilterChips>
  <FhProductFilterNav
    :show-filter-nav="openAllFilters"
    :filters="props.filters"
    :filter-model="model"
    :total-active-filter-option-count="totalActiveFilterOptionCount"
    :facet-counts="props.facetCounts"
    :total-products-count="props.totalProductsCount"
    :sort-options="props.sortOptions"
    :sort-model="sortModel"
    @update:show-filter="handleOpenAllFilters"
    @update:model-value="(val) => (model = val)"
    @update:sort-model="(val) => (sortModel = val)"
    @clear-all="clearAll"
  />
</template>

<style lang="pcss" scoped>
:deep(.btn.allFilter__cta) {
  @media (max-width: 1199px) {
    @apply pl-0;
  }
}
.c-productFilterBar:before {
  @apply absolute top-px left-1/2 -z-10 h-full w-[var(--100vw)] -translate-x-1/2 border-b border-inherit bg-inherit content-[""];
}

:deep(.sortFilter__header) {
  @apply flex items-center;
}

:deep(.sortFilter__header-title) {
  @apply text-f-sm-lg;
}

:deep(.sortFilter__filters li) {
  @apply my-2;
}
:deep(.sortFilter__filters) {
  @apply absolute z-50 bg-white shadow-md p-4 w-64 mt-3;
}
:deep(.sortFilter__filter) {
  @apply text-f-sm-lg;
}
</style>
