<script setup>
import { ref, reactive } from "vue";
import { getDataRefs } from "@/util/vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { useUpdatePasswordMutation } from "@/api/authApi";
import { useNotificationStore } from "../stores/NotificationStore";
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhIcon from "./FhIcon.vue";
import FhInput from "@/components/FhInput.vue";
import FhModal from "@/components/FhModal.vue";
import FhNewPassword from "@/components/FhNewPassword.vue";
import FhSection from "@/includes/FhSection.vue";

const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  }
});

const { heading, customerIdLabel, customerId } = getDataRefs(props.data);

const isModalOpen = ref(false);

const model = reactive({
  currentPassword: "",
  newPassword: ""
});

const $externalResults = ref({});

const vuelidate = useVuelidate(
  {
    currentPassword: {
      required: helpers.withMessage("Please enter your current password.", required)
    }
  },
  model,
  { $externalResults }
);

const serverErrorMessages = ref([]);

const { mutate: updatePasswordMutate, isLoading: isUpdating } = useUpdatePasswordMutation();
const notificationStore = useNotificationStore();

async function updatePassword() {
  if (await vuelidate.value.$validate()) {
    serverErrorMessages.value = [];
    updatePasswordMutate(model, {
      onSuccess: handleUpdatePasswordSuccess,
      onError: handleUpdatePasswordError
    });
  }
}

function handleUpdatePasswordSuccess() {
  isModalOpen.value = false;
  notificationStore.notifySuccess("Password updated.");
}

function handleUpdatePasswordError(error) {
  const { data, status } = error.response;
  if (status === 400 && data?.errors?.length) {
    data.errors.forEach((err) => {
      if (err.code === "PasswordMismatch") {
        $externalResults.value = { currentPassword: "Incorrect password." };
      } else {
        serverErrorMessages.value.push(err.description);
      }
    });
  } else {
    notificationStore.notifyError("Sorry, something went wrong and we could not update your password. Please try again.");
  }
}

function reset() {
  model.currentPassword = "";
  model.newPassword = "";
  vuelidate.value.$reset();
}
</script>

<template>
  <FhSection :base="style.base">
    <div class="relative mb-[60px] flex flex-col pb-[70px] text-neutral-70 md:border-b md:border-solid md:border-neutral-70 md:border-opacity-10 md:pb-[60px]">
      <FhButton
        class="absolute bottom-0 left-0 right-0 md:top-0 md:left-auto md:right-0 md:bottom-auto"
        aria-label="Update Password"
        @click="isModalOpen = true"
      >
        Update Password
      </FhButton>
      <FhHeading tag="h2">{{ heading }}</FhHeading>
      <div class="mb-4 flex flex-col md:mb-2 md:flex-row">
        <span class="mb-0.5 text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12">
          {{ customerIdLabel }}
        </span>
        <span class="text-neutral-50">
          {{ customerId }}
        </span>
      </div>
      <div class="mb-4 flex flex-col md:mb-2 md:flex-row">
        <span class="mb-0.5 flex flex-col text-f-sm-base font-bold md:mb-0 md:basis-4/12 lg:basis-3/12">Password</span>
        <span class="text-neutral-570">***************</span>
      </div>
    </div>
    <FhModal v-model:is-open="isModalOpen" :data="{ heading: 'Update Password' }" @close="reset">
      <template #body>
        <form id="updatePasswordForm" class="flex flex-col gap-f4" @submit.prevent="updatePassword">
          <FhInput
            v-model="model.currentPassword"
            type="password"
            label="Current Password"
            placeholder="Enter current password"
            :vuelidate="vuelidate.currentPassword"
            @input="() => vuelidate.$clearExternalResults()"
          />
          <FhNewPassword v-model="model.newPassword" />
          <div v-if="serverErrorMessages.length" role="alert" class="text-body-sm flex flex-row gap-f1 text-brand-red">
            <FhIcon name="AlertCircle" class="relative top-px text-[125%]" />
            <ul>
              <li v-for="errorMessage in serverErrorMessages" :key="errorMessage">{{ errorMessage }}</li>
            </ul>
          </div>
        </form>
      </template>
      <template #footer="{ close }">
        <FhButton type="submit" form="updatePasswordForm" color="primary" :disabled="vuelidate.$invalid || isUpdating" :is-loading="isUpdating">Save</FhButton>
        <FhButton variant="text" @click="close">Cancel</FhButton>
      </template>
    </FhModal>
  </FhSection>
</template>
