<script setup>
import { ref } from "vue";
import TransitionExpand from "../../transitions/TransitionExpand.vue";
import IconChevronDown from "../../assets/icon-chevron-down.svg";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

defineProps({
  items: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    default: ""
  }
});

const isActive = ref(false);
</script>

<template>
  <!-- ACCORDION ITEM -->
  <div class="border-b border-neutral-20">
    <!-- ACCORDION title -->
    <button
      class="flex w-full cursor-pointer list-none items-start py-f4 text-neutral-70"
      :aria-expanded="isActive"
      :aria-controls="`content-${title}`"
      @click.prevent="isActive = !isActive"
    >
      <!-- title TITLE -->
      <span class="pointer-events-none w-full grow basis-auto text-left text-f-base-2xl">
        {{ title }}
      </span>
      <span class="pointer-events-none ml-8 shrink pt-1.5 pr-6">
        <img
          :src="IconChevronDown"
          alt="Chevron Down"
          class="ChevronDown h-2 w-3.5 transition-transform duration-200 ease-linear lg:h-3 lg:w-5"
          :class="{ 'rotate-180': isActive }"
        />
      </span>
    </button>
    <TransitionExpand>
      <!-- ACCORDION CONTENT -->
      <div v-if="isActive" :id="`content-${title}`" :aria-hidden="!isActive">
        <div :class="['mb-f5 ml-1 flex flex-col pl-f5 text-f-sm-xl md:ml-1.5 md:pr-f25 lg:ml-0']">
          <FhWysiwyg>
            <template v-for="(item, i) in items" :key="`${i}-${item.title}`">
              <strong class="mb-4 block text-f-sm-base text-inherit">{{ item.title }}</strong>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p class="mb-8 text-f-sm-base text-inherit last:mb-0" v-html="item.copy"></p>
            </template>
          </FhWysiwyg>
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>
