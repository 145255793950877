<script setup>
import { computed } from "vue";
import FhSaveForLaterPresenter from "@/components/FhSaveForLaterPresenter.vue";
import { useSaveForLaterQuery, useDeleteSaveForItemMutation, useAddToCartMutation } from "@/api/saveforlaterApi";
import { useNotificationStore } from "../stores/NotificationStore.js";
const { isLoading, isError, data: saveForLaterData } = useSaveForLaterQuery();
const items = computed(() => (isLoading.value ? [] : saveForLaterData.value));

const removeCartItemMutation = useDeleteSaveForItemMutation();
const addCartItemsMutation = useAddToCartMutation();
const notificationStore = useNotificationStore();

const isLoadingState = computed(() => {
  return isLoading.value || removeCartItemMutation.isLoading.value || addCartItemsMutation.isLoading.value;
});

function notifyItemRemoveSuccess(cartItem) {
  notificationStore.notifySuccess(`${cartItem.sku.productName} removed.`);
}

function notifyItemRemoveError(cartItem) {
  notificationStore.notifyError(`Sorry, something went wrong and we could not remove ${cartItem.sku.productName}. Please try again.`);
}

async function handleItemDelete(item) {
  let cartItem = items.value.find((x) => x.id === item.id);
  try {
    //saveForLaterData.value = true;
    await removeCartItemMutation.mutateAsync(item);
    notifyItemRemoveSuccess(cartItem);
  } catch {
    // saveForLaterData.value = false;
    notifyItemRemoveError(cartItem);
  }
}

function notifyAddToCartSuccess(cartItem) {
  notificationStore.notifySuccess(`${cartItem.sku.productName} Added To Cart.`);
}

function notifyAddToCartError(cartItem) {
  notificationStore.notifyError(`Sorry, something went wrong and we could not add ${cartItem.sku.productName} to Cart Please try again.`);
}
function handleMoveToCart(items) {
  const data = [{ skuNumber: items.sku.skuNumber, quantity: items.quantity, id: items.id }];

  addCartItemsMutation.mutate(data, {
    onSuccess: () => {
      //saveForLaterData.value = true;
      notifyAddToCartSuccess(items);
    },
    onError: () => {
      notifyAddToCartError(items);
    }
  });
}
function notifyAddAllToCartSuccess() {
  notificationStore.notifySuccess(`All Save for Later Items Added To Cart`);
}

function notifyAddAllToCartError() {
  notificationStore.notifyError(`Sorry, something went wrong and we could not add All to Cart Please try again.`);
}
function handleMoveAllToCart(items) {
  const itemsToMoveMap = items.map((item) => {
    return { skuNumber: item.sku.skuNumber, quantity: item.quantity, id: item.id };
  });
  addCartItemsMutation.mutate(itemsToMoveMap, {
    onSuccess: () => {
      notifyAddAllToCartSuccess();
    },
    onError: () => {
      notifyAddAllToCartError();
    }
  });
}
</script>

<template>
  <div class="pb-f8">
    <FhSaveForLaterPresenter
      v-model="items"
      :is-loading="isLoadingState"
      :is-error="isError"
      @item-delete="handleItemDelete"
      @movetocart="handleMoveToCart"
      @movealltocart="handleMoveAllToCart"
    />
  </div>
</template>
