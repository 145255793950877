<script setup>
import { ref, reactive, computed, onMounted, watch, nextTick } from "vue";
import { cloneDeep } from "lodash";
import { useSubmitTradeApplicationMutation } from "../api/tradeApplicationApi";
import { useNotificationStore } from "@/stores/NotificationStore.js";
import FhButton from "@/components/FhButton.vue";
import SidebarImg from "@/assets/img/trade_program_sidebar.jpg";
import FhTPAProgress from "./FhTradeProgramApp/FhTPA-Progress.vue";
import Page1 from "@/components/FhTradeProgramApp/FhTPA-Page-1.vue";
import Page2 from "@/components/FhTradeProgramApp/FhTPA-Page-2.vue";
import Page3 from "@/components/FhTradeProgramApp/FhTPA-Page-3.vue";
import Page4 from "@/components/FhTradeProgramApp/FhTPA-Page-4.vue";
import Page5 from "@/components/FhTradeProgramApp/FhTPA-Page-5.vue";
import Page6 from "@/components/FhTradeProgramApp/FhTPA-Page-6.vue";
import FhFullscreenFocusedLayout from "@/components/FhFullscreenFocusedLayout.vue";

const isComplete = ref(false);

const currentPage = ref(1);
const previousPage = ref(1);

const layout = ref(null);

const pageTransition = computed(() => {
  if (currentPage.value > previousPage.value) return "page-next";
  else return "page-prev";
});

const currentPageComponent = computed(() => {
  switch (currentPage.value) {
    case 1:
      return Page1;
    case 2:
      return Page2;
    case 3:
      return Page3;
    case 4:
      return Page4;
    case 5:
      return Page5;
    case 6:
      return Page6;
  }
  return "div";
});

const navigateTo = (pageNumber) => {
  layout.value.scrollContainer.scrollTo({ top: 0 });
  previousPage.value = currentPage.value;
  if (pageNumber === -1) {
    window.history.pushState({ page: --currentPage.value }, "", "");
  } else {
    window.history.pushState({ page: pageNumber }, "", "");
    currentPage.value = pageNumber;
  }
};

onMounted(() => {
  window.history.replaceState({ page: currentPage.value }, "", "");
  window.onpopstate = (e) => {
    previousPage.value = currentPage.value;
    currentPage.value = e.state.page;
  };
});

const serviceOptions = reactive([
  {
    value: "retailer",
    label: "I run a physical retail store or series of stores."
  },
  {
    value: "residential-design",
    label: "I offer interior design services and/or buy for residential properties."
  },
  {
    value: "commercial-design",
    label: "I offer design services and/or buy for use in commercial spaces."
  },
  {
    value: "e-commerce",
    label: "I sell online and ship directly to customers."
  }
]);

const primaryProjectTypeOptions = reactive([
  {
    value: "commercial-contract",
    label: "Home builder or staging, office design, property management"
  },
  {
    value: "commercial-hospitality",
    label: "Hospitality spaces like hotels, resorts, spas & restaurants."
  },
  {
    value: "commercial-specialty",
    label: "Other - please describe"
  }
]);

const projectTypeOtherOptions = reactive([
  {
    value: "residential-design",
    label: "Residential Homes"
  },
  {
    value: "commercial-contract",
    label: "Home builder or staging, office design, property management"
  },
  {
    value: "commercial-hospitality",
    label: "Hospitality spaces like hotels, resorts, spas & restaurants."
  },
  {
    value: "commercial-specialty",
    label: "Other - please describe"
  }
]);

const options = computed(() => {
  switch (currentPage.value) {
    case 1:
      return serviceOptions;
    case 2:
      return primaryProjectTypeOptions;
    case 3:
      if (model.value.primaryBusiness === "residential-design") {
        return projectTypeOtherOptions.filter((o) => !o.value.includes(model.value.primaryBusiness));
      }
      if (model.value.primaryProject && model.value.primaryProject.primaryProjectType) {
        return projectTypeOtherOptions.filter((o) => !o.value.includes(model.value.primaryProject.primaryProjectType));
      }
      return projectTypeOtherOptions;
  }
  return false;
});

const model = ref({
  primaryBusiness: null,
  primaryProject: {
    primaryProjectType: null,
    primaryProjectTypeOther: ""
  },
  projectType: {
    projectTypeOther: [],
    projectTypeOtherOther: ""
  },
  businessContact: {
    name: "",
    registeredLocation: "US",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: ""
  },
  businessDetails: {
    locations: "",
    revenue: "",
    staff: "",
    services: [],
    servicesOther: "",
    description: "",
    brands: "",
    websiteEcommCapable: "",
    social: {
      website: {
        selected: false,
        user: ""
      },
      instagram: {
        selected: false,
        user: ""
      },
      facebook: {
        selected: false,
        user: ""
      },
      pinterest: {
        selected: false,
        user: ""
      },
      other: {
        selected: false,
        network: "",
        user: ""
      }
    }
  },
  contact: {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    confirmEmail: "",
    phone: "",
    terms: false
  }
});

const currentPageModel = computed({
  get() {
    switch (currentPage.value) {
      case 1:
        return model.value.primaryBusiness;
      case 2:
        return model.value.primaryProject;
      case 3:
        return model.value.projectType;
      case 4:
        return model.value.businessContact;
      case 5:
        return model.value.businessDetails;
      case 6:
        return model.value.contact;
    }
    return {};
  },
  set(value) {
    const _model = cloneDeep(model.value);
    switch (currentPage.value) {
      case 1:
        _model.primaryBusiness = value;
        break;
      case 2:
        _model.primaryProject = value;
        break;
      case 3:
        _model.projectType = value;
        break;
      case 4:
        _model.businessContact = value;
        break;
      case 5:
        _model.businessDetails = value;
        break;
      case 6:
        _model.contact = value;
        break;
    }
    model.value = _model;
  }
});

const form = ref(null);
const selectFirstInput = () => {
  nextTick(() => form.value.querySelector("input, select, textarea")?.focus());
};
onMounted(selectFirstInput);
watch(currentPage, selectFirstInput);

const { mutate: submitTradeApplication, isLoading: isSubmitting } = useSubmitTradeApplicationMutation();

function submit() {
  submitTradeApplication(model.value, {
    onSuccess: () => {
      isComplete.value = true;
    },
    onError: () => {
      useNotificationStore().notifyError("Sorry, something went wrong and we could not submit your application. Please try again.");
    }
  });
}
</script>

<template>
  <FhFullscreenFocusedLayout ref="layout" :background-image="SidebarImg" :hide-header-on-mobile="currentPage !== 1 && !isComplete" align-content="top">
    <div ref="form" class="grid min-h-full grid-rows-[auto_1fr]">
      <template v-if="!isComplete">
        <FhTPAProgress :step="currentPage" :total="6" class="relative after:absolute after:left-0 after:-top-f8 after:h-f8 after:w-full after:bg-white" />
        <transition :name="pageTransition">
          <component
            :is="currentPageComponent"
            v-model="currentPageModel"
            :options="options"
            :model="model"
            :is-submitting="isSubmitting"
            class="col-start-1 row-start-2"
            @submit="submit"
            @navigate="navigateTo"
          >
          </component>
        </transition>
      </template>
      <template v-else>
        <div class="row-span-2 mt-f8 flex flex-col gap-f3 self-center md:-translate-y-f5">
          <h1 class="mb-f3 font-serif text-f-5xl-9xl">Thank You, {{ model.businessContact.name }}</h1>
          <p class="mb-f5">Your application has been received. We will be in touch shortly.</p>
          <!-- <p class="mb-f5">Your application has been received. We will be in touch within one business day.</p> -->
          <FhButton href="/" color="primary" class="md:self-start">Return to FourHands.com</FhButton>
        </div>
      </template>
    </div>
  </FhFullscreenFocusedLayout>
</template>

<style lang="pcss" scoped>
.page-next-enter-active,
.page-next-leave-active,
.page-prev-enter-active,
.page-prev-leave-active {
  transition: 0.75s ease;
}

.page-next-enter-from,
.page-prev-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.page-next-leave-to,
.page-prev-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
