<script setup>
import { reactive, watch, toRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, email, maxLength } from "@vuelidate/validators";
import FhButton from "@/components/FhButton.vue";
// checkbox component is no longer needed unless pay carrier checkbox is added back
// import FhCheckbox from "@/components/FhCheckbox.vue";
import FhCheckoutHoldDateSelect from "@/components/FhCheckoutHoldDateSelect.vue";
import FhInput from "@/components/FhInput.vue";
import FhInputPhone from "@/components/FhInputPhone.vue";
import { getHoldDateLimits } from "@/util/dateFunctions";

const props = defineProps({
  carrierName: {
    type: String,
    default: null
  },
  carrierAccountNumber: {
    type: String,
    default: null
  },
  carrierPhone: {
    type: String,
    default: null
  },
  carrierEmail: {
    type: String,
    default: null
  },
  carrierContactName: {
    type: String,
    default: null
  },
  carrierNotes: {
    type: String,
    default: null
  },
  // prop is no longer needed unless pay carrier checkbox is added back
  // payOnDelivery: {
  //   type: Boolean,
  //   default: false
  // },
  holdDate: {
    type: String,
    default: null
  },
  isSaving: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["complete"]);

const data = reactive({
  carrierName: props.carrierName,
  carrierAccountNumber: props.carrierAccountNumber,
  carrierPhone: props.carrierPhone,
  carrierEmail: props.carrierEmail,
  carrierContactName: props.carrierContactName,
  carrierNotes: props.carrierNotes,
  // prop is no longer needed unless pay carrier checkbox is added back
  // payOnDelivery: props.payOnDelivery,
  holdDate: props.holdDate
});

// Update `data` values in response to corresponding props changing
Object.keys(data).forEach((key) => watch(toRef(props, key), (val) => (data[key] = val)));

const isDateValid = (value) => {
  return !helpers.req(value) || (value <= maxDate && value >= minDate);
};

const { minDate, maxDate } = getHoldDateLimits();

const characterLimit = 100;
const vuelidate = useVuelidate(
  {
    carrierName: {
      required: helpers.withMessage("Please enter a carrier name.", required),
      maxLength: helpers.withMessage(`Please enter no more than ${characterLimit} characters.`, maxLength(characterLimit))
    },
    carrierAccountNumber: {
      required: helpers.withMessage("Please enter a carrier account number.", required),
      maxLength: helpers.withMessage(`Please enter no more than ${characterLimit} characters.`, maxLength(characterLimit))
    },
    carrierPhone: {
      required: helpers.withMessage("Please enter a carrier phone number.", required),
      validPhone: helpers.withMessage("Please enter a valid carrier phone number.", helpers.regex(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/))
    },
    carrierEmail: {
      required: helpers.withMessage("Please enter a carrier email address.", required),
      validEmail: helpers.withMessage("Please enter a valid carrier email address.", email),
      maxLength: helpers.withMessage("Please enter no more than 254 characters.", maxLength(254))
    },
    carrierContactName: {
      required: helpers.withMessage("Please enter a carrier contact name.", required),
      maxLength: helpers.withMessage("Please enter no more than 50 characters.", maxLength(50))
    },
    holdDate: {
      isDateValid: helpers.withMessage("Please choose a date within 90 days from today.", isDateValid)
    }
  },
  data
);

function complete() {
  if (vuelidate.value.$validate()) {
    emit("complete", { ...data });
  }
}
</script>

<template>
  <div class="mt-f5 flex flex-col gap-f8">
    <fieldset>
      <legend class="mb-f4 text-f-base-xl">Enter the carrier information</legend>
      <div class="grid gap-x-f3 gap-y-f4 md:grid-cols-2">
        <FhInput v-model="data.carrierName" label="Carrier Name" required :vuelidate="vuelidate.carrierName" />
        <FhInput
          v-model="data.carrierAccountNumber"
          label="Carrier Account Number"
          required
          :vuelidate="vuelidate.carrierAccountNumber"
          note="If you don't have an account number, enter N/A."
        />
        <FhInputPhone v-model="data.carrierPhone" label="Carrier Phone Number" :vuelidate="vuelidate.carrierPhone" required />
        <FhInput v-model="data.carrierEmail" label="Carrier Email Address" :vuelidate="vuelidate.carrierEmail" required />
        <FhInput v-model="data.carrierContactName" label="Carrier Contact Name" :vuelidate="vuelidate.carrierContactName" required />
        <!-- not needed unless pay carrier checkbox is added back
        <div class="col-span-2">
          <FhCheckbox v-model="data.payOnDelivery" label="I will pay the carrier upon delivery" />
        </div> -->
      </div>
    </fieldset>
    <FhCheckoutHoldDateSelect v-model="data.holdDate" :min-date="minDate" :max-date="maxDate" :vuelidate="vuelidate" />
    <div>
      <div class="mb-f4 text-f-base-xl">Additional Details <span class="text-neutral-50">(Optional)</span></div>
      <FhInput
        v-model="data.carrierNotes"
        label="Carrier and/or Freight Broker Instructions"
        placeholder="Ex. 24 hour call ahead"
        :wrapper-attrs="{ class: 'max-w-prose' }"
        note="Special requests, such as 24 hr call ahead ($50) and Liftgate ($200), require an additional charge and are typically only available for Expedited and Rush service. If you are shipping internationally, please include freight broker name, contact and phone number here."
      />
    </div>
    <FhButton color="primary" class="w-full" :disabled="vuelidate.$invalid || props.isSaving" @click="complete">Continue to Payment</FhButton>
  </div>
</template>

<style scoped></style>
