<script setup>
import { computed, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, maxLength } from "@vuelidate/validators";
import { useViewportSizes } from "@/composables";
import COUNTRIES from "@/constants/countries";
import FhButton from "@/components/FhButton.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhInput from "@/components/FhInput.vue";
import FhSelect from "@/components/FhSelect.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  model: {
    type: Object,
    required: true
  }
});

// Compute the page to return to when the "Back" link is clicked, based on where they came from
const prevPage = computed(() => (props.model.primaryBusiness == "commercial-design" || props.model.primaryBusiness == "residential-design" ? 3 : 1));

const emit = defineEmits(["update:modelValue", "navigate"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value)
});

const countryOptions = Object.keys(COUNTRIES).map((key) => ({ value: key, text: COUNTRIES[key].name }));

const hasStateOptions = computed(() => {
  return value.value.registeredLocation === "US" || value.value.registeredLocation === "CA";
});
const country = computed(() => COUNTRIES[value.value.registeredLocation]);
const stateLabel = computed(() => country.value.stateName ?? "State/Province/Region");
const stateOptions = computed(() =>
  country.value.states ? Object.keys(country.value.states).map((key) => ({ value: key, text: country.value.states[key] })) : []
);
const postalCodeLabel = computed(() => country.value.postalCodeName ?? "Postal Code");
const postalCodeRegex = computed(() => country.value.postalCodeRegex);

const vuelidate = useVuelidate(
  {
    name: {
      required: helpers.withMessage("Please enter a business name.", required),
      maxLength: helpers.withMessage("Please keep your entry to no more than 30 characters.", maxLength(30))
    },
    registeredLocation: { required: helpers.withMessage("Please select a country.", required) },
    address1: {
      required: helpers.withMessage("Please enter an address.", required),
      maxLength: helpers.withMessage("Please keep your entry to no more than 30 characters.", maxLength(30))
    },
    address2: {
      maxLength: helpers.withMessage("Please keep your entry to no more than 30 characters.", maxLength(30))
    },
    city: {
      required: helpers.withMessage("Please enter a city.", required)
    },
    state: {
      required: helpers.withMessage(
        () => (hasStateOptions.value ? `Please select a ${stateLabel.value.toLowerCase()}.` : `Please enter a ${stateLabel.value.toLowerCase()}.`),
        required
      )
    },
    zip: {
      required: helpers.withMessage(() => `Please enter a ${postalCodeLabel.value.toLowerCase()}.`, required),
      validPostalCode: helpers.withMessage(
        () => `Please enter a valid ${postalCodeLabel.value.toLowerCase()}.`,
        (value) => !helpers.req(value) || !postalCodeRegex.value || postalCodeRegex.value.test(value)
      )
    }
  },
  value
);

watch(
  () => value.value.registeredLocation,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      // Reset state value
      value.value.state = "";

      // Trigger validation
      vuelidate.state.$touch();
    }
  }
);

const { isSm } = useViewportSizes();
</script>

<template>
  <div class="flex flex-col">
    <FhHeading heading="Business Contact Details" subheading="Please enter your business contact information." />
    <div class="grid grid-cols-1 gap-x-f3 gap-y-f4 lg:grid-cols-2">
      <FhInput v-model="value.name" label="Business Name" required :vuelidate="vuelidate.name" :wrapper-attrs="{ class: 'lg:col-span-2' }" />
      <FhSelect
        v-model="value.registeredLocation"
        label="Country"
        :options="countryOptions"
        required
        :vuelidate="vuelidate.registeredLocation"
        :wrapper-attrs="{ class: 'lg:col-span-2' }"
      />
      <FhInput v-model="value.address1" label="Address Line 1" required :vuelidate="vuelidate.address1" :wrapper-attrs="{ class: 'lg:col-span-2' }" />
      <FhInput v-model="value.address2" label="Address Line 2" :vuelidate="vuelidate.address2" :wrapper-attrs="{ class: 'lg:col-span-2' }" />
      <FhInput v-model="value.city" label="City" required :vuelidate="vuelidate.city" />
      <component
        :is="hasStateOptions ? FhSelect : FhInput"
        v-model="value.state"
        :options="stateOptions"
        :label="stateLabel"
        required
        :vuelidate="vuelidate.state"
      />
      <FhInput v-model="value.zip" :label="postalCodeLabel" required :vuelidate="vuelidate.zip" />
    </div>
    <div class="mt-f8 flex items-center gap-f3 md:self-end">
      <FhButton :variant="isSm ? false : 'text'" :class="{ 'flex-1': isSm }" @click="emit('navigate', prevPage)">Back</FhButton>
      <FhButton :disabled="vuelidate.$invalid" color="primary" :class="{ 'flex-1': isSm }" @click="emit('navigate', 5)">Continue</FhButton>
    </div>
  </div>
</template>

<style lang="pcss" scoped></style>
