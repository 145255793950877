<script setup>
import { ref, computed } from "vue";
import FhCartItem from "@/components/FhCartItem.vue";
import FhLoader from "@/components/FhLoader.vue";
import FhHeading from "@/components/FhHeading.vue";
import FhButton from "@/components/FhButton.vue";
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  hasError: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  totalItemsCount: {
    type: Number,
    required: true
  }
});
const emit = defineEmits(["itemDelete", "movetocart", "moveAllToCart"]);
const saveForLaterItems = computed(() => props.modelValue);
const isEmpty = computed(() => saveForLaterItems.value.length === 0);
const totalItem = computed(() => props.modelValue.reduce((total, item) => total + item.quantity, 0));
const hasError = computed(() => props.hasError);
const isItemRemoveAnimationComplete = ref(true);
</script>

<template>
  <div v-if="props.isLoading" class="w-full"><FhLoader class="h-10 w-10" /></div>
  <div>
    <div class="sm:grid-cols-1 grid md:grid-cols-2">
      <FhHeading class="headerText mb-0 py-f4">Saved for Later</FhHeading>

      <div v-if="totalItem > 0" class="button addButton justify-end gap-5 self-center text-right md:mt-0 md:flex">
        <FhButton class="mb-2.5 w-full md:w-auto" @click="emit('movealltocart', saveForLaterItems)">Move All to Cart ({{ totalItem }}) </FhButton>
      </div>
    </div>
    <div v-if="!isEmpty && !hasError && !props.isLoading" class="grid gap-f5 border-y border-neutral-30 md:grid-cols-2 lg:grid-cols-3">
      <TransitionGroup
        :leave-active-class="`transition ease-in duration-200 ${props.modelValue.length > 0 ? '!absolute' : ''}`"
        leave-from-class="translate-x-0 opacity-100"
        leave-to-class="-translate-x-1/2 opacity-0"
        move-class="transtion duration-500"
        @before-leave="() => (isItemRemoveAnimationComplete = false)"
        @after-leave="() => (isItemRemoveAnimationComplete = true)"
      >
        <FhCartItem
          v-for="saveForLaterItem in saveForLaterItems"
          :key="saveForLaterItem.id || saveForLaterItem.key"
          :is-save-for-later="true"
          v-bind="saveForLaterItem"
          @delete="() => emit('itemDelete', { id: saveForLaterItem.id })"
          @movetocart="movetocartitem = emit('movetocart', saveForLaterItem)"
        />
      </TransitionGroup>
    </div>
  </div>
  <div v-if="hasError && !props.isLoading">
    <p class="text-body-lg mb-f2 text-neutral-50">Sorry, we are not able to load your save for later items.</p>
  </div>
  <div v-else-if="isEmpty && !props.isLoading">
    <p class="text-body-lg mb-f2 text-neutral-50">Your Save For Later list is empty.</p>
  </div>
</template>
