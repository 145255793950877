<script setup>
import { computed } from "vue";
import { debounce } from "lodash";
import formatPrice from "../util/formatPrice.js";
import FhButton from "./FhButton.vue";
import FhImage from "./FhImage.vue";
import FhIcon from "./FhIcon.vue";
import FhQuantityInput from "./FhQuantityInput.vue";

const props = defineProps({
  /**
   * Puts the cart item in a view-only state where quantity cannot be edited and the actions aren't available.
   */
  viewOnly: {
    type: Boolean,
    default: false
  },
  quantity: {
    type: Number,
    required: true
  },
  unitPrice: {
    type: Number,
    required: true
  },
  isDiscounted: {
    type: Boolean,
    default: false
  },
  originalUnitPrice: {
    type: Number,
    default: 0
  },
  totalPrice: {
    type: Number,
    required: true
  },
  sku: {
    type: Object,
    required: true
  },
  isSaveForLater: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:quantity", "delete", "movetocart", "saveforlater"]);

const _quantity = computed({
  get: () => props.quantity,
  set: debounce((val) => {
    if (val && val !== props.quantity) {
      emit("update:quantity", val);
    }
  }, 500)
});

const isUnavailable = computed(() => props.sku.isAvailable === false);
</script>

<template>
  <div
    class="relative border-solid border-neutral-30 py-5 md:py-8"
    :class="{
      'bg-neutral-10 before:absolute before:top-0 before:left-1/2 before:-z-10 before:h-full before:w-[calc(100%+40px)] before:-translate-x-1/2 before:bg-neutral-10':
        isUnavailable,
      'border-y': !props.isSaveForLater
    }"
  >
    <div class="flex flex-col gap-4 md:flex-row md:items-start md:justify-between md:gap-f5">
      <div class="flex gap-3 md:min-w-0 md:gap-f5">
        <a :href="props.sku.url" class="block w-32 flex-none md:w-f25">
          <FhImage :src="props.sku.thumbUrl" :alt="props.sku.productName" square :border="true" />
        </a>
        <div class="flex min-w-0 flex-col gap-1" :class="{ 'text-neutral-50': isUnavailable, 'md:mt-f3': !props.isSaveForLater }">
          <a :href="props.sku.url" class="whitespace-pre-line text-f-base-2xl md:mb-f0.5 md:whitespace-normal">{{ props.sku.productName }}</a>
          <div class="flex gap-f1.5 text-f-xs-base">
            <span :class="{ 'text-brand-red': props.isDiscounted }">{{ formatPrice(props.unitPrice) }}</span>
            <span v-if="props.originalUnitPrice" class="line-through">{{ formatPrice(props.originalUnitPrice) }}</span>
          </div>
          <div class="truncate text-f-xs-base text-neutral-50">
            <span v-if="props.sku.skuName">{{ props.sku.skuName }}&nbsp;&bull;&nbsp;</span>
            <span>{{ props.sku.skuNumber }}</span>
          </div>
          <div
            v-if="props.isSaveForLater"
            class="mt-1 flex items-center justify-between gap-3 md:relative md:right-0 md:flex-none portrait:relative"
            :class="{ 'text-neutral-50': isUnavailable, 'md:mt-f3': !props.isSaveForLater }"
          >
            <div v-if="props.viewOnly || props.isSaveForLater" class="text-neutral-50">Qty: {{ props.quantity }}</div>
            <FhQuantityInput v-else v-model="_quantity" :disabled="isUnavailable" />
          </div>
          <div v-if="props.sku.inStock && !isUnavailable" class="text-f-xs-base">In Stock <FhIcon name="LightningBolt" class="relative -top-px" /></div>
          <div v-if="isUnavailable" class="mt-f0.5 font-bold"><FhIcon name="AlertCircle" class="relative -top-0.5" /> This item is no longer available</div>

          <div
            v-if="!props.viewOnly"
            class="sm:right-0 flex w-full gap-5 md:bottom-f5 md:right-0"
            :class="props.isSaveForLater ? 'sm:mt-3 sm:justify-end mt-auto md:justify-start' : 'justify-end md:absolute md:mt-3'"
          >
            <FhButton variant="link" @click="emit('delete')">Remove</FhButton>
            <FhButton v-if="!isUnavailable && !props.isSaveForLater" variant="link" @click="emit('saveforlater')">Save for Later</FhButton>
            <FhButton v-if="!isUnavailable && props.isSaveForLater" variant="link" @click="emit('movetocart')">Move to Cart</FhButton>
          </div>
        </div>
      </div>

      <div
        v-if="!props.isSaveForLater"
        class="flex items-center justify-between gap-3 md:absolute md:right-0 md:mt-f3 md:flex-none"
        :class="{ 'text-neutral-50': isUnavailable }"
      >
        <div v-if="props.viewOnly" class="text-neutral-50">Qty: {{ props.quantity }}</div>
        <FhQuantityInput v-else v-model="_quantity" :disabled="isUnavailable" />
        <div v-show="!props.isSaveForLater" class="text-f-base-2xl md:w-[6.5em] md:min-w-min md:text-right">{{ formatPrice(props.totalPrice) }}</div>
      </div>
    </div>
  </div>
</template>
