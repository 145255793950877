<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import FhHeading from "@/components/FhHeading.vue";
import FhIcon from "./FhIcon.vue";
import FhShoppingListProductGrid from "@/components/FhShoppingListProductGrid.vue";
import {
  useShoppingListDetailQuery,
  useDeleteShoppingItemMutation,
  useDeleteShoppingListMutation,
  useUpdateShoppingListMutation,
  useUpdateItemMutation,
  useAddToCartMutation,
  useAddAllToCartMutation
} from "@/api/shoppingListApi";
import FhButton from "./FhButton.vue";
import { useNotificationStore } from "@/stores/NotificationStore";
import FhModal from "@/components/FhModal.vue";
import FhShoppingListForm from "@/components/FhShoppingListForm.vue";
import formatPrice from "../util/formatPrice.js";
import FhShareModal from "./FhShareModal.vue";
import IconNewWindow from "../assets/icons/icon-new-window.svg";
const { isLoading: updateMutationLoading, mutate: updateShoppingListMutate } = useUpdateShoppingListMutation(null, true);
const { isLoading: deleteMutationLoading, mutate: deleteShoppingListMutate } = useDeleteShoppingListMutation(null, true);
const { isLoading: removeCartItemLoading, mutate: removeCartItemMutation } = useDeleteShoppingItemMutation(null, true);
const { isLoading: AddAllToCartLoading, mutate: addAllToCartMutation } = useAddAllToCartMutation();
const { isLoading: AddToCartLoading, mutate: addToCartMutation } = useAddToCartMutation();
const { isLoading: updateCartItemLoading, mutate: updateItemMutation } = useUpdateItemMutation(null, true);

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  description: {
    type: String,
    default: ""
  },
  header: {
    type: String,
    default: ""
  },
  guid: {
    type: String,
    default: "1"
  },
  isOwner: {
    type: Boolean,
    default: false
  },
  isPreview: {
    type: Boolean,
    default: false
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  priceMarkup: {
    type: Object,
    default: () => {}
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  userName: {
    type: String,
    default: ""
  }
});

let { isLoading, data: shoppingListData } = useShoppingListDetailQuery(props.guid, props.isPreview);

const notificationStore = useNotificationStore();
const shoppingLists = computed(() => (isLoading.value ? [] : shoppingListData.value));
const shoppingListId = computed(() => shoppingListData.value?.id || null);
const markup = ref(props.priceMarkup?.markup || 0);
const showPriceMarkup = ref(props.priceMarkup?.showPriceMarkup || false);
const showCADPricing = ref(props.priceMarkup?.showCADPricing || false);
const handleItemDelete = (item) => {
  const data = { ShoppingListID: shoppingLists.value.id, id: item.id };
  removeCartItemMutation(data, {
    onSuccess: () => {
      notificationStore.notifySuccess("Item removed.");
    },
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not remove that shopping list.")
  });
};
const showDeleteModal = ref(false);
const showForm = ref(false);
const isEditForm = ref(false);
const shoppingListToModify = ref(null);
const showPopup = ref(false);
const showSharedList = ref(false);
const shoppingListSharedLink = ref(null);
const shouldShowPricing = computed(() => {
  if (shoppingLists.value.length == 0) return false;
  if (shoppingLists.value.totalPrice !== 0) return true;
  return false;
});

const handleDeleteShoppingList = () => {
  showDeleteModal.value = true;
};
const closeDeleteModal = () => {
  showDeleteModal.value = false;
};
const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const removeShoppingList = () => {
  deleteShoppingListMutate(shoppingListId.value, {
    onSuccess: () => notificationStore.notifySuccess("Shopping List removed."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not remove that shopping list."),
    onSettled: () => {
      closeDeleteModal();
      window.location.href = "/shopping-lists";
    }
  });
};

const updateClick = () => {
  shoppingListToModify.value = shoppingLists.value;
  isEditForm.value = true;
  showForm.value = true;
};

const shoppingListModalTrigger = (val) => {
  showForm.value = val;
  shoppingListToModify.value = null;
  isEditForm.value = false;
};

const updateShoppingList = (shoppingListDetails) => {
  updateShoppingListMutate(shoppingListDetails, {
    onSuccess: () => notificationStore.notifySuccess("Shopping list updated."),
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not update that Shopping list."),
    onSettled: () => {
      shoppingListModalTrigger(false);
      shoppingListToModify.value = null;
      isEditForm.value = false;
      showPopup.value = false;
    }
  });
};
const handleClickTriggers = (click_type) => {
  switch (click_type) {
    case "update": {
      updateClick();
      break;
    }
    case "delete": {
      handleDeleteShoppingList();
      break;
    }
  }
};

const itemKeyTouches = ref({});

function touchItemKey(itemId) {
  itemKeyTouches.value = {
    ...itemKeyTouches.value,
    [itemId]: (itemKeyTouches.value[itemId] || 0) + 1
  };
}
function handleItemQuantityUpdate(item) {
  const data = { ShoppingListID: shoppingLists.value.id, SkuNumber: item.SkuNumber, Quantity: item.Quantity };
  updateItemMutation(data, {
    onSuccess: () => {
      notifyItemQuantityUpdateSuccess();
    },
    onError: () => {
      touchItemKey(item.id);
      notifyItemQuantityUpdateError();
    }
  });
}
function notifyItemQuantityUpdateSuccess() {
  notificationStore.notifySuccess(` Quantity updated.`);
}

function notifyItemQuantityUpdateError() {
  notificationStore.notifyError(`Sorry, something went wrong and we could not update the quantity of. Please try again.`);
}

const closePopup = () => {
  showPopup.value = false;
};
const sharedList = (val) => {
  showSharedList.value = val;
  const pathArray = window.location.href;
  shoppingListSharedLink.value = pathArray;
};

function viewAsClient() {
  const url = window.location.href;
  const base_url = url.substring(0, url.lastIndexOf("/") + 1);
  const detailPageUrl = `${base_url}preview/${props.guid}`;
  window.open(detailPageUrl, "_blank");
}

function notifyAddAllToCartSuccess() {
  notificationStore.notifySuccess(`All Shopping List Items Added To Cart`);
}

function notifyAddAllToCartError() {
  notificationStore.notifyError(`Sorry, something went wrong and we could not add All to Cart Please try again.`);
}
function handleAddAllToCart() {
  addAllToCartMutation(props.guid, {
    onSuccess: () => {
      notifyAddAllToCartSuccess();
    },
    onError: () => {
      notifyAddAllToCartError();
    }
  });
}
const handleAddToCart = (item) => {
  const data = { SkuNumber: item.skuNumber, Quantity: item.Quantity };
  addToCartMutation(data, {
    onSuccess: () => {
      notificationStore.notifySuccess("Shopping List Items Added To Cart");
    },
    onError: () => notificationStore.notifyError("Sorry, something went wrong and we could not add to Cart Please try again.")
  });
};

const closePopupOnClick = (event) => {
  if (!(event.target.closest(".toggle-button") !== null)) {
    showPopup.value = false;
  }
};

onMounted(() => {
  window.addEventListener("click", closePopupOnClick);
});

onBeforeUnmount(() => {
  window.removeEventListener("click", closePopupOnClick);
});
</script>

<template>
  <div class="grid grid-cols-1 gap-2 md:grid-cols-2 pt-12">
    <FhHeading class="headerText relative mb-0" :subheading="shoppingLists.description"
      >{{ shoppingLists.title }}
      <span v-if="(!props.isPreview && props.isOwner) || (shoppingLists.isAdmin && !shoppingLists.isAdminShoppingList && !props.isPreview)" class="relative">
        <div class="inline-block toggle-button" @click="togglePopup">
          <FhIcon name="HorizontalEllipse" class="ml-4 rounded-full border border-solid border-neutral-30 p-2 hover:cursor-pointer" />
        </div>
        <div v-if="showPopup" ref="popup" class="absolute left-10 mt-[-1.5rem] bg-white shadow-lg">
          <div class="action-button relative p-4">
            <button
              class="bg-blue-500 hover:bg-blue-600 hover:text-red-500 block rounded text-base hover:underline"
              variant="link"
              :aria-label="AriaClickText"
              @click="
                handleClickTriggers('update', shoppingLists.id);
                closePopup();
              "
            >
              Edit
            </button>
            <button
              class="bg-blue-500 hover:bg-blue-600 hover:text-red-500 mt-2 block rounded text-base hover:underline"
              variant="link"
              :aria-label="AriaClickText"
              @click="
                handleClickTriggers('delete', shoppingLists.id);
                closePopup();
              "
            >
              Remove
            </button>
          </div>
        </div>
      </span>
    </FhHeading>
    <div v-if="shoppingLists.isAdmin && !shoppingLists.isAdminShoppingList && !props.isPreview" class="items-top flex w-full bg-limestone py-f3 px-f3">
      <FhIcon name="InformationCircle" class="mr-4" />
      <div class="mx-auto max-w-max">
        <span class="mr-f2 inline-block font-bold"
          >You're viewing your Guest Account's Shopping List with your Wholesale pricing. To view this Shopping List with your Guest Account's pricing, click
          "View as Client".</span
        >
      </div>
    </div>
  </div>
  <FhModal :data="{ heading: 'Confirm Shopping List Deletion' }" :is-open="showDeleteModal" @close="closeDeleteModal">
    <template #body>
      <p>This action will permanently delete this shopping list.</p>
    </template>
    <template #footer="{ close }">
      <FhButton color="primary" :disabled="deleteMutationLoading" :is-loading="deleteMutationLoading" @click="removeShoppingList">Delete</FhButton>
      <FhButton variant="text" @click="close">Cancel</FhButton>
    </template>
  </FhModal>
  <div class="top-block grid-col-1 mt-6 block justify-between md:grid md:grid-cols-3 md:border md:border-solid md:border-neutral-30 portrait:grid-cols-2">
    <div class="item-block mb-3 flex w-full items-center justify-between md:mb-0 md:grid-cols-2 md:flex-row md:justify-start">
      <div class="bg-blue-500 border-solid border-neutral-30 p-0 md:border-r md:p-3">
        <!-- Show this on mobile -->
        <div class="w-32 truncate text-f-xs-base text-neutral-50 md:hidden">
          <span>{{ shoppingLists.totalItems }} Items</span>
        </div>
        <!-- Show this on screens larger than mobile -->
        <div class="sm:block hidden w-32 truncate text-f-xs-base text-neutral-50 md:block">
          <span>Items in List</span>
          <div class="mr-f3 text-f-sm-lg">{{ shoppingLists.totalItems }}</div>
        </div>
      </div>

      <div class="bg-blue-500 flex items-center justify-end border-solid border-neutral-30 p-0 md:p-3" :class="{ 'md:border-r': shouldShowPricing }">
        <!-- Show this on mobile -->
        <div v-if="shouldShowPricing" class="truncate text-f-xs-base text-neutral-50 md:hidden">
          <span
            >{{ isOwner && !isPreview ? "Your Price" : "Total" }} :
            <span
              v-if="
                (props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin) || (props.isCADPrice && props.isAdmin && props.isPreview)
              "
              >CAD</span
            >{{ formatPrice(shoppingLists?.totalPrice || 0) }}</span
          >
        </div>
        <!-- Show this on screens larger than mobile -->
        <div v-if="shouldShowPricing" class="sm:block hidden w-32 truncate text-f-xs-base text-neutral-50 md:block">
          <span>{{ isOwner && !isPreview ? "Your Price" : "Total" }}</span>
          <div class="mr-f3 text-f-sm-lg">
            <span
              v-if="
                (props.isCADPrice && props.isCustomerGuest) || (props.isCADPrice && !props.isAdmin) || (props.isCADPrice && props.isAdmin && props.isPreview)
              "
              >CAD</span
            >{{ formatPrice(shoppingLists?.totalPrice || 0) }}
          </div>
        </div>
      </div>
    </div>

    <div class="empty-block md:grid-cols-1 md:flex-row md:justify-end"></div>
    <div
      v-if="(!props.isPreview && shoppingLists.isAdmin) || (!props.isPreview && props.isOwner)"
      class="action-block flex flex-wrap items-center portrait:flex-nowrap"
      :class="!props.isOwner ? (shoppingLists.isAdmin && shoppingLists.isAdminShoppingList ? 'justify-end' : 'justify-between') : 'justify-between'"
    >
      <div v-if="props.isOwner || (shoppingLists.isAdmin && !shoppingLists.isAdminShoppingList)" class="bg-blue-500 mb-3 rounded-md md:mb-0">
        <FhButton variant="text" class="group w-full md:w-auto" @click="viewAsClient">
          <FhIcon name="PasswordReveal" class="relative -top-px" /> View As Client
          <img
            class="visible ml-2 transition-all duration-200 group-hover:visible group-hover:opacity-100 lg:invisible lg:opacity-0"
            :src="IconNewWindow"
            alt=""
          />
        </FhButton>
      </div>
      <div v-if="props.isOwner || (shoppingLists.isAdmin && !shoppingLists.isAdminShoppingList)" class="bg-blue-500 mb-3 rounded-md p-0 md:mb-0 md:p-3">
        <FhButton variant="text" class="w-full md:w-auto" @click="sharedList(true)"><FhIcon name="Share" class="relative -top-px" /> Share List</FhButton>
      </div>
      <div v-if="shoppingLists.isAdmin && shoppingLists.products.length > 0" class="bg-blue-500 w-full rounded-md p-0 md:w-auto md:p-3">
        <FhButton color="primary" size="small" class="w-full md:w-auto" @click="handleAddAllToCart"> Add All To Cart </FhButton>
      </div>
    </div>
  </div>

  <FhShoppingListProductGrid
    :products="shoppingLists.products"
    :is-admin="shoppingLists.isAdmin"
    :is-shopping-list="true"
    :is-loading="removeCartItemLoading || AddAllToCartLoading || AddToCartLoading || updateCartItemLoading"
    :is-preview="props.isPreview"
    :is-owner="props.isOwner"
    :is-c-a-d-price="props.isCADPrice"
    :is-customer-guest="props.isCustomerGuest"
    :is-admin-shopping-list="shoppingLists.isAdminShoppingList"
    @additemto-Cart="handleAddToCart"
    @item-delete="handleItemDelete"
    @item-quantity-update="handleItemQuantityUpdate"
  ></FhShoppingListProductGrid>
  <FhShoppingListForm
    v-if="showForm"
    :is-open="showForm"
    :is-creating="updateMutationLoading"
    :shopping-details="shoppingListToModify"
    :edit-mode="isEditForm"
    :is-admin="shoppingLists.isAdmin"
    :markup="markup"
    :show-price-markup="showPriceMarkup"
    :show-c-a-d-pricing="showCADPricing"
    :is-c-a-d-price="props.isCADPrice"
    :is-customer-guest="props.isCustomerGuest"
    :is-admin-shopping-list="shoppingLists.isAdminShoppingList"
    @update="updateShoppingList"
    @update:is-open="shoppingListModalTrigger"
  ></FhShoppingListForm>
  <FhShareModal
    v-if="showSharedList"
    :is-open="showSharedList"
    :is-creating="showSharedList"
    :is-admin="shoppingLists.isAdmin"
    :path-url="shoppingListSharedLink"
    @update:is-open="sharedList"
  ></FhShareModal>
</template>
<style scoped>
@media (min-width: 768px) and (max-width: 1024px) {
  .empty-block {
    display: none;
  }
  .top-block .col-md-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .top-block {
    border-width: 0px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .item-block div {
    border-right-width: 0px;
  }
  .action-block {
    grid-column: span 2 / span 2;
  }
}
/* Styles for iPhone */
@media (max-width: 767px) {
  .action-block {
    flex-wrap: wrap !important;
  }
}
.action-button {
  font-family: "Universal Sans";
}
</style>
