<script setup>
import { computed } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import FhProductFilterBarItem from "@/components/FhProductFilterBarItem.vue";
import FhButton from "@/components/FhButton.vue";
import FhIcon from "@/components/FhIcon.vue";
import FhProductFilterChips from "@/components/Products/FhProductFilterChips.vue";
import FhSortFilter from "@/components/Partials/FhSortFilter/FhSortFilter.vue";

const props = defineProps({
  showFilterNav: {
    type: Boolean,
    default: false
  },
  filters: {
    type: Array,
    required: true
  },
  filterModel: {
    type: Object,
    required: true,
    default: () => ({})
  },
  facetCounts: {
    type: Object,
    default: () => ({})
  },
  sortOptions: {
    type: Array,
    default: () => []
  },
  sortModel: {
    type: String,
    default: ""
  },
  totalActiveFilterOptionCount: {
    type: Number,
    default: 0
  },
  totalProductsCount: {
    type: Number,
    default: 0
  }
});

const emits = defineEmits(["update:showFilter", "update:modelValue", "update:sortModel", "clearAll"]);

const showFilterNav = computed({
  get: () => props.showFilterNav,
  set: (val) => emits("update:showFilter", val)
});

const model = computed({
  get: () => props.filterModel,
  set: (val) => emits("update:modelValue", val)
});

const sortModel = computed({
  get: () => props.sortModel,
  set: (val) => emits("update:sortModel", val)
});

const handleOpenAllFilters = (event) => {
  if (event) event.preventDefault();
  showFilterNav.value = !showFilterNav.value;
};

const getFacetCountByKey = (filterKey) => {
  const facet = Object.values(props.facetCounts).find((facet) => facet.key === filterKey);

  return facet;
};

const clearAll = () => {
  emits("clearAll");
};
</script>

<template>
  <TransitionRoot as="template" :show="showFilterNav">
    <Dialog as="div" class="relative z-10" @close="handleOpenAllFilters">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral-opacity-35 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="sm:px-6 border-b border-b-neutral-30 px-4 py-5 pl-3.5 md:px-10">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-base font-semibold leading-6">Filters</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="text-gray-400 rounded-md bg-white" @click="handleOpenAllFilters">
                          <span class="sr-only">Close panel</span>
                          <FhButton size="small" variant="round" class="relative" aria-label="Close Filters Sidebar" @click="close">
                            <FhIcon name="XIcon" class="!text-2xl" />
                          </FhButton>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="sm:px-6 relative grid h-full flex-1 grid-flow-row grid-rows-[auto_1fr_auto]">
                    <FhProductFilterChips
                      :filters="props.filters"
                      :filter-model="model"
                      :facet-counts="props.facetCounts"
                      @update:model-value="(val) => (model = val)"
                    ></FhProductFilterChips>
                    <section class="filters overflow-y-auto overflow-x-hidden px-3.5 pt-6 md:px-10">
                      <FhSortFilter
                        v-if="props.sortOptions.length > 0"
                        v-model="sortModel"
                        :sort-options="props.sortOptions"
                        :is-menu-open="true"
                        :keep-menu-open="true"
                        class="mr-f2 w-full border-b border-b-neutral-30 py-f2"
                      />
                      <FhProductFilterBarItem
                        v-for="filter in props.filters"
                        :key="filter.key"
                        v-bind="filter"
                        :model-value="model[filter.key]"
                        :facet-counts="getFacetCountByKey(filter.key)"
                        :is-menu-item="true"
                        class="mr-f2 w-full"
                        @update:model-value="(val) => (model = { ...model, [filter.key]: val })"
                      />
                    </section>
                    <section class="flex h-[180px] items-start justify-center gap-3 border-t border-t-neutral-30 py-8 px-3.5 md:px-10">
                      <FhButton class="grow" variant="standard" color="secondary" @click="clearAll">Clear All</FhButton>
                      <FhButton class="grow" variant="standard" color="primary" @click="handleOpenAllFilters"
                        >View <template v-if="props.totalActiveFilterOptionCount > 0">({{ props.totalProductsCount }})</template></FhButton
                      >
                    </section>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="pcss">
:deep(.sortFilter__header) {
  @apply flex items-center justify-between text-f-base-lg font-bold py-f2;
}
:deep(.sortFilter__header-title--descriptor) {
  @apply hidden;
}
:deep(.sortFilter__header-icon) {
  font-size: 150%;
}
:deep(.sortFilter__filters li) {
  @apply my-2;
}
:deep(.sortFilter__filter) {
  @apply text-f-base-lg;
}
</style>
