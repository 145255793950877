import { createApp } from "vue";
import * as components from "./components";
import registerVuePlugins from "./plugins";
import CreateWebComponent from "./util/vue/_createWebComponent";
import { envrionmentTypes } from "@/constants/envrionment";
import * as Sentry from "@sentry/vue";

import "./css/main.css";

import viewportSizes from "./util/viewportSizes";
window.addEventListener("DOMContentLoaded", viewportSizes);

function isKenticoPageBuilder() {
  return document.documentElement.classList.contains("kentico-page-builder");
}

/**
 * Special Vue initialization within Kentico page builder

 * Our standard Vue app setup doesn't work because page builder loads widgets asynchronously and injects them into the DOM after Vue is initialzed.
 * Here, we instead wrap each published Vue component in a custom element (of the same name) that initializes that component as a standalone app.
 * This might break some cross-component communication (e.g., via Pinia) since there is not global app instance, but that should be OK within
 * the Kentico page builder environment where the components need to be visually correct, but perhaps not functionally correct.
 */
function initVueForKenticoPageBuilder() {
  for (const componentName in components) {
    new CreateWebComponent(componentName, components[componentName], (app) => registerVuePlugins(app));
  }
}

function initlizeSentry(app) {
  Sentry.init({
    app,
    dsn: "https://ef27796c94554ec99403e424a76f6c6d@o163743.ingest.sentry.io/4505037021970432",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [...Object.values(envrionmentTypes.envrionments).flat(), /^\//]
      })
    ],
    environment: envrionmentTypes.getEnvrionment(window.location.hostname),
    tracesSampleRate: 0.2
  });
}

/**
 * Normal Vue initialization (single app instance)
 */
function initVueForLiveSite() {
  const app = createApp();
  registerVuePlugins(app);
  initlizeSentry(app);
  for (const componentName in components) {
    app.component(componentName, components[componentName]);
  }
  app.mount("#app");
}

/**
 * Main entrypoint
 */
if (isKenticoPageBuilder()) {
  initVueForKenticoPageBuilder();
} else {
  initVueForLiveSite();
}
