<script setup>
import { ref, reactive, computed, toRef, watch, nextTick, provide } from "vue";
import { useViewportSizes } from "@/composables";
import { useShippingAddressesQuery, useVerifyShippingAddressMutation } from "@/api/shippingAddressApi";
import { SHIPPING_OPTIONS, SHIPPING_OPTIONS_DEFINITION } from "@/constants/checkout";
import { useShippingAddressStore } from "@/stores/ShippingAddressStore";
import scrollToTopOf from "@/util/scrollToTopOf";
import FhButton from "@/components/FhButton.vue";
import FhCard from "@/components/FhCard.vue";
import FhModal from "@/components/FhModal.vue";
import FhTooltip from "@/components/FhTooltip.vue";
import FhOptionCard from "@/components/FhOptionCard.vue";
import FhShippingAddress from "@/components/FhShippingAddress.vue";
import FhShippingAddressList from "@/components/FhShippingAddressList.vue";

const props = defineProps({
  shippingOption: {
    type: String,
    default: null,
    validator: (val) => Object.values(SHIPPING_OPTIONS).includes(val)
  },
  shippingAddressId: {
    type: String,
    default: null
  },
  isSaving: {
    type: Boolean,
    default: false
  },
  carriersTooltip: {
    type: String,
    default: null
  },
  thirdPartyTooltip: {
    type: String,
    default: null
  },
  pickUpTooltip: {
    type: String,
    default: null
  },
  swatchOnlyCart: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["complete"]);
const shippingAddressListRef = ref(null);

const data = reactive({
  shippingOption: props.shippingOption,
  shippingAddressId: props.shippingAddressId,
  swatchOnlyCart: props.swatchOnlyCart
});

// Update `data` values in response to corresponding props changing
Object.keys(data).forEach((key) => watch(toRef(props, key), (val) => (data[key] = val)));

// Clear selected shipping address when:
// 1. The shipping option is FH Carriers and the selected shipping address cannot accept freight
// 2. The shipping option is Pick-Up (because shipping address is not relevant)
watch(
  () => data.shippingOption,
  (val) => {
    if ((val === SHIPPING_OPTIONS.FH_CARRIERS && selectedAddress.value?.isValidatedForFreight === false) || val === SHIPPING_OPTIONS.PICK_UP) {
      data.shippingAddressId = null;
    }

    nextTick(() => scrollToTopOf(shippingAddressListRef.value));
  }
);

// This will provide a subheading to the FhShippingAddressFormModal
const shippingAddressFormSubHeading = computed(() =>
  data.shippingOption === SHIPPING_OPTIONS.FH_CARRIERS
    ? `Four Hands carriers do not deliver to residential addresses and require a commercial address that can accommodate a 53' freight truck. Should you choose to proceed with a residential address, you will be prompted to choose another address or arrange your own carrier on the next page`
    : null
);
provide("addressFormSubhead", shippingAddressFormSubHeading);

const isShippingAddressSelectionRequired = computed(() => [SHIPPING_OPTIONS.FH_CARRIERS, SHIPPING_OPTIONS.THIRD_PARTY].includes(data.shippingOption));

const isValid = computed(() => data.shippingOption && (!isShippingAddressSelectionRequired.value || data.shippingAddressId));

const { data: shippingAddresses } = useShippingAddressesQuery({ includeSingleUse: true });
const selectedAddress = computed(() => shippingAddresses.value?.find((address) => address.id === data.shippingAddressId));

const { isLoading: isVerifying, mutate: verifyShippingAddress } = useVerifyShippingAddressMutation();

const { addressIdsConfirmedForFreight } = useShippingAddressStore();

const isVerifyingNewAddress = ref(false);
const showAddressVerificationModal = ref(false);
const showAddressIneligibleModal = ref(false);

function handleAddressCreate(newAddress) {
  if (data.shippingOption === SHIPPING_OPTIONS.FH_CARRIERS) {
    if (newAddress.isValidatedForFreight === false) {
      isVerifyingNewAddress.value = true;
      showAddressIneligibleModal.value = true;
    } else if (newAddress.isValidatedForFreight === null) {
      isVerifyingNewAddress.value = true;
      showAddressVerificationModal.value = true;
    }
  }
}

const isAddressVerificationRequired = computed(
  () =>
    data.shippingOption === SHIPPING_OPTIONS.FH_CARRIERS &&
    selectedAddress.value?.isValidatedForFreight === null &&
    !addressIdsConfirmedForFreight.includes(data.shippingAddressId)
);

function tryComplete() {
  if (isAddressVerificationRequired.value) {
    verifyShippingAddress(data.shippingAddressId, {
      onSuccess: handleAddressVerificationSuccess,
      onError: handleAddressVerificationError
    });
  } else {
    complete();
  }
}

function handleAddressVerificationSuccess(updatedAddress) {
  if (updatedAddress.isValidatedForFreight) {
    emit("complete", { ...data });
  } else if (updatedAddress.isValidatedForFreight === false) {
    isVerifyingNewAddress.value = false;
    showAddressIneligibleModal.value = true;
  } else {
    isVerifyingNewAddress.value = false;
    showAddressVerificationModal.value = true;
  }
}

function handleAddressVerificationError() {
  isVerifyingNewAddress.value = false;
  showAddressVerificationModal.value = true;
}

function complete() {
  emit("complete", { ...data });
}

const shouldKeepSelectedAddress = ref(false);

function clearSelectedAddressIfNeeded() {
  if (shouldKeepSelectedAddress.value) {
    shouldKeepSelectedAddress.value = false;
  } else {
    data.shippingAddressId = null;
  }
}

const { isSm } = useViewportSizes();
</script>

<template>
  <div class="flex flex-col gap-f8">
    <fieldset>
      <legend class="mb-f4 text-f-base-xl">How would you like to receive your order?</legend>
      <div class="flex flex-col gap-f3 md:flex-row">
        <div class="relative flex-1">
          <FhOptionCard
            v-model="data.shippingOption"
            type="radio"
            :layout="isSm ? 'row' : 'column'"
            :value="SHIPPING_OPTIONS.FH_CARRIERS"
            :label="SHIPPING_OPTIONS_DEFINITION[SHIPPING_OPTIONS.FH_CARRIERS].displayName"
            class="h-full w-full"
          />
          <FhTooltip class="absolute top-3 right-3 text-neutral-60">
            {{ carriersTooltip }}
          </FhTooltip>
        </div>
        <div v-if="!swatchOnlyCart" class="relative flex-1">
          <FhOptionCard
            v-model="data.shippingOption"
            type="radio"
            :layout="isSm ? 'row' : 'column'"
            :value="SHIPPING_OPTIONS.THIRD_PARTY"
            :label="SHIPPING_OPTIONS_DEFINITION[SHIPPING_OPTIONS.THIRD_PARTY].displayName"
            class="h-full w-full"
          />
          <FhTooltip class="absolute top-3 right-3 text-neutral-60">
            {{ thirdPartyTooltip }}
          </FhTooltip>
        </div>
        <div v-if="!swatchOnlyCart" class="relative flex-1">
          <FhOptionCard
            v-model="data.shippingOption"
            type="radio"
            :layout="isSm ? 'row' : 'column'"
            :value="SHIPPING_OPTIONS.PICK_UP"
            :label="SHIPPING_OPTIONS_DEFINITION[SHIPPING_OPTIONS.PICK_UP].displayName"
            class="h-full w-full"
          />
          <FhTooltip class="absolute top-3 right-3 text-neutral-60">
            {{ pickUpTooltip }}
          </FhTooltip>
        </div>
      </div>
    </fieldset>
    <FhShippingAddressList
      v-show="isShippingAddressSelectionRequired"
      v-model:selected-id="data.shippingAddressId"
      mode="select"
      :require-freight="data.shippingOption === SHIPPING_OPTIONS.FH_CARRIERS"
      heading-row-alignment="end"
      @create="handleAddressCreate"
    >
      <template #heading>
        <div ref="shippingAddressListRef" class="flex flex-col gap-f0.5">
          <div class="text-f-base-xl">Select or add the delivery address</div>
          <div v-if="data.shippingOption === SHIPPING_OPTIONS.FH_CARRIERS" class="text-neutral-50 md:max-w-[30rem]">
            Four Hands carriers do not deliver to residential addresses and require a commercial address that can accommodate a
            <span class="whitespace-nowrap font-bold text-neutral-70">53' freight truck</span>. Ineligible addresses will be greyed out below.
          </div>
        </div>
      </template>
    </FhShippingAddressList>
    <FhButton v-if="data.shippingOption" color="primary" :disabled="!isValid || props.isSaving || isVerifying" class="self-end" @click="tryComplete">
      Continue
    </FhButton>
    <FhModal v-model:is-open="showAddressVerificationModal" :data="{ heading: 'Verify Address for Freight' }" @after-leave="clearSelectedAddressIfNeeded">
      <template #default>
        <p class="mb-f5">
          Four Hands carriers can only deliver to commercial addresses that can accommodate a 53' freight truck. To proceed, please confirm this address can
          accept freight deliveries.
        </p>
        <FhCard>
          <FhShippingAddress v-if="selectedAddress" v-bind="selectedAddress" />
        </FhCard>
      </template>
      <template #footer>
        <FhButton
          color="primary"
          :disabled="props.isSaving"
          @click="
            () => {
              addressIdsConfirmedForFreight.push(data.shippingAddressId);
              if (isVerifyingNewAddress) {
                shouldKeepSelectedAddress = true;
                showAddressVerificationModal = false;
              } else {
                complete();
              }
            }
          "
        >
          Yes, it accepts freight
        </FhButton>
        <FhButton variant="text" @click="showAddressVerificationModal = false">Choose another address</FhButton>
      </template>
    </FhModal>
    <FhModal v-model:is-open="showAddressIneligibleModal" :data="{ heading: 'Address Cannot Accept Freight' }" @after-leave="clearSelectedAddressIfNeeded">
      <template #default>
        <p class="mb-f5">
          Our records indicate the delivery address you've selected cannot accept freight. Four Hands carriers do not deliver to residential addresses and
          require a commercial address that can accommodate a 53' freight truck. Please choose a different delivery address or arrange your own carrier.
        </p>
        <FhCard>
          <FhShippingAddress v-if="selectedAddress" v-bind="selectedAddress" />
        </FhCard>
      </template>
      <template #footer>
        <FhButton
          size="small"
          class="flex-1"
          :disabled="props.isSaving"
          @click="
            () => {
              data.shippingOption = SHIPPING_OPTIONS.THIRD_PARTY;
              if (isVerifyingNewAddress) {
                shouldKeepSelectedAddress = true;
                showAddressIneligibleModal = false;
              } else {
                complete();
              }
            }
          "
        >
          Arrange my own carrier
        </FhButton>
        <FhButton size="small" class="flex-1" @click="showAddressIneligibleModal = false">Choose another address</FhButton>
      </template>
    </FhModal>
  </div>
</template>

<style scoped></style>
