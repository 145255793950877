<script setup>
import FhSection from "@/includes/FhSection.vue";
import { getDataRefs } from "@/util/vue";
// import vue swiper
import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
// import swiper css
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import FhVideo from "@/components/FhVideo.vue";
const props = defineProps({
  style: {
    type: Object,
    required: false,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true
  },
  breakpoints: {
    type: Object,
    required: false,
    default: () => ({
      0: {
        slidesPerView: 1.25,
        spaceBetween: 8
      },
      350: {
        slidesPerView: 2.25,
        spaceBetween: 8
      },
      950: {
        slidesPerView: 3.5,
        spaceBetween: 20
      },
      1300: {
        slidesPerView: 4.5,
        spaceBetween: 20
      }
    })
  },
  imagesRatio: {
    type: String,
    default: "1/1"
  },
  isVideo: {
    type: Boolean,
    default: false
  }
});

const { slides, gaTagging } = getDataRefs(props.data);
</script>

<template>
  <FhSection
    v-if="slides.length > 0"
    aria-label="Image Carousel"
    :base="style.base"
    :class="{
      'text-white': ['slate', 'umber', 'sage'].includes(props.style?.base?.background)
    }"
  >
    <div class="fhImageCarousel">
      <swiper :modules="[Navigation, Scrollbar]" :breakpoints="breakpoints" :threshold="20" :scrollbar="{ draggable: true }" navigation>
        <swiper-slide v-for="slide in slides" :key="slide.id" class="flex">
          <a class="h-full w-full" :href="slide.ctaLink" :aria-label="slide.ctaAltText" :target="slide.ctaTarget" :data-ga="gaTagging || 'image_carousel'">
            <figure>
              <div class="fhImageCarousel__image relative block">
                <FhVideo v-if="slide.isVideo" :src="slide.imageSrc" :autoplay="true" :loop="true" :controls="false" class="video_block"></FhVideo>
                <img
                  v-else
                  class="absolute left-0 right-0 top-0 bottom-0 h-full w-full object-cover object-center"
                  :data-src="slide.imageSrc"
                  :src="slide.imageSrc"
                  :alt="slide.imageAltText"
                />
              </div>
              <figcaption v-if="slide.imageCaption" class="mt-f3">
                {{ slide.imageCaption }}
              </figcaption>
            </figure>
          </a>
        </swiper-slide>
      </swiper>
    </div>
  </FhSection>
</template>

<style lang="pcss" scoped>
.fhImageCarousel {
  &:deep(.swiper) {
    @apply pt-f10 pb-f6;
  }
  &:deep(.swiper-slide) {
    @apply h-auto;
  }
  &:deep(.swiper-button-prev),
  &:deep(.swiper-button-next) {
    @apply top-6 hidden h-4 w-4 md:flex;
  }
  &:deep(.swiper-button-prev) {
    @apply left-auto right-[6rem] bg-contain bg-center bg-no-repeat;
    background-image: url("../assets/icons/icon-arrow-prev.svg");
  }
  &:deep(.swiper-button-next) {
    @apply right-[3rem] bg-contain bg-center bg-no-repeat;
    background-image: url("../assets/icons/icon-arrow-next.svg");
  }
  &:deep(.swiper-button-prev::after),
  &:deep(.swiper-button-next::after) {
    @apply hidden;
  }
  &:deep(.swiper-scrollbar) {
    /* @apply !left-4 lg:!left-8 !bottom-0 !w-[calc(100%-2rem)] lg:!w-[calc(100%-4rem)]; */
    @apply left-0 w-full;
    height: 17px;
    border-radius: 0;
    background: transparent;
    &::before {
      @apply bg-neutral-30;
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      right: 0;
      bottom: 8px;
    }
  }
  &:deep(.swiper-scrollbar-drag) {
    @apply bg-slate;
    cursor: pointer;
    height: 1px;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-radius: 0;
    background-clip: content-box;
    box-sizing: content-box;
  }
}

.fhImageCarousel__image {
  aspect-ratio: v-bind(props.imagesRatio);
}

:deep(.fh_video) {
  @apply absolute left-0 right-0 top-0 bottom-0 h-full w-full object-cover object-center;
}

.video_block {
  @apply static;
}

/* Apply filter invert(100%) conditionally */
.text-white :deep(.swiper-button-prev),
.text-white :deep(.swiper-button-next) {
  filter: invert(100%) brightness(10000%) contrast(1000%);
}
.text-white :deep(.swiper-scrollbar-drag) {
  @apply bg-white-opacity-70;
}
.text-white :deep(.swiper-scrollbar::before) {
  @apply bg-neutral-opacity-80;
}
</style>
