<script setup>
import { computed } from "vue";
import { FREIGHT_SERVICE_LEVEL, FREIGHT_SERVICE_LEVEL_DEFINITION } from "@/constants";
import formatPrice from "@/util/formatPrice";
import { useViewportSizes } from "@/composables";
import FhIcon from "@/components/FhIcon.vue";
import FhModal from "@/components/FhModal.vue";
import FhOptionCard from "@/components/FhOptionCard.vue";
import FhWysiwyg from "@/includes/FhWysiwyg.vue";

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true
  },
  freightServiceLevel: {
    type: String,
    required: true,
    validator: (val) => Object.values(FREIGHT_SERVICE_LEVEL).includes(val)
  },
  price: {
    type: Number,
    default: null
  },
  priceToBeQuoted: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val)
});

const { isSm } = useViewportSizes();
</script>

<template>
  <FhOptionCard v-model="value" type="radio" :value="props.freightServiceLevel">
    <div class="flex items-center justify-between">
      <div>
        <div class="text-body-lg flex items-center gap-3">
          <span>{{ FREIGHT_SERVICE_LEVEL_DEFINITION[props.freightServiceLevel].displayName }}</span>
          <FhModal :data="{ heading: FREIGHT_SERVICE_LEVEL_DEFINITION[props.freightServiceLevel].displayName + ' Service' }">
            <template #activator="{ open }">
              <button class="flex items-center" title="See more details about this service level" @click="open"><FhIcon name="InformationCircle" /></button>
            </template>
            <template #default>
              <FhWysiwyg :content="FREIGHT_SERVICE_LEVEL_DEFINITION[props.freightServiceLevel].longDescription" spacing />
            </template>
          </FhModal>
        </div>
        <div class="text-neutral-50">{{ FREIGHT_SERVICE_LEVEL_DEFINITION[props.freightServiceLevel].description }}</div>
      </div>
      <div v-if="props.isLoading" class="text-body-lg w-16 animate-pulse bg-neutral-20">&nbsp;</div>
      <div v-else-if="props.isError || props.priceToBeQuoted" class="text-body text-right">
        <span v-if="isSm">Quoted</span>
        <span v-else>Quote provided at time of shipment</span>
      </div>
      <div v-else-if="props.price !== null" class="text-body-lg text-right">{{ formatPrice(props.price) }}</div>
    </div>
  </FhOptionCard>
</template>
