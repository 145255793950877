<script setup>
import { ref, computed } from "vue";
import { useTimeAgo } from "@vueuse/core";
import { CHECKOUT } from "@/constants/routes";
import FhAlert from "./FhAlert.vue";
import FhButton from "./FhButton.vue";
import FhCartCheckoutLayout from "./FhCartCheckoutLayout.vue";
import FhCartItem from "./FhCartItem.vue";
import FhCartSummary from "./FhCartSummary.vue";
import FhIcon from "./FhIcon.vue";

const props = defineProps({
  totalItemsCount: {
    type: Number,
    required: true
  },
  totalItemsPrice: {
    type: Number,
    required: true
  },
  availabilityLastUpdatedDate: {
    type: Date,
    default: () => null
  },
  items: {
    type: Array,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  },
  shippingTooltip: {
    type: String,
    default: null
  },
  taxTooltip: {
    type: String,
    default: null
  },
  cartMessaging: {
    type: String,
    default: null
  }
});

const emit = defineEmits(["itemQuantityUpdate", "itemDelete", "itemEnter", "saveforlater"]);

const availabilityUpdatedTimeAgo = useTimeAgo(() => props.availabilityLastUpdatedDate);

const isDataLoaded = computed(() => !props.isLoading && !props.isError);
const isEmpty = computed(() => isDataLoaded.value && props.items.length === 0);

const unavailableItems = computed(() => props.items.filter((item) => item.sku.isAvailable === false));

const cutoverSkus = computed(() => props.items.filter((item) => !!item.sku.oldSku));

const isCheckoutDisabled = computed(() => props.isLoading || props.isError || isEmpty.value || unavailableItems.value.length > 0);

const hasWallpaperItems = computed(() => props.items.some((item) => item.category && item.category.toLowerCase().includes("wallpaper")));

const isItemRemoveAnimationComplete = ref(true);

const cartSummaryProps = computed(() => {
  let total = isDataLoaded.value ? props.totalItemsPrice : null;
  return {
    totalItemsPrice: total,
    totalPrice: total,
    shippingTooltip: props.shippingTooltip,
    taxTooltip: props.taxTooltip
  };
});

function removeUnavailable() {
  unavailableItems.value.forEach((item) => emit("itemDelete", { id: item.id }));
}

function checkout() {
  window.location = CHECKOUT;
}
</script>

<template>
  <FhCartCheckoutLayout>
    <template #default>
      <!-- CART HEADER -->
      <div class="mb-8 flex flex-col gap-f3 md:mb-f6 md:flex-row md:items-end md:justify-between">
        <h1 class="font-serif text-f-2xl-7xl">Shopping Cart</h1>
        <div v-if="isDataLoaded" class="text-f-xs-lg">
          <span>{{ props.totalItemsCount }} {{ props.totalItemsCount === 1 ? "item" : "items" }}</span>
          <span class="mx-f1.5 inline-block text-neutral-50">&bull;</span>
          <span class="text-neutral-50">Last inventory update: {{ availabilityUpdatedTimeAgo }}</span>
        </div>
      </div>

      <!-- CUTOVER ITEMS MESSAGE -->
      <FhAlert v-if="cutoverSkus.length > 0" icon="InformationCircle" class="mb-8 md:mb-f6">
        <div class="">
          <ul class="list-outside">
            <li v-for="(item, index) in cutoverSkus" :key="item.sku.skuNumber" :class="{ 'mb-3': index !== cutoverSkus.length - 1 }">
              {{ item.sku.productName }} (formally item number {{ item.sku.oldSku }}) has been replaced with {{ item.sku.skuNumber }}. This is the same product
              with a new SKU number. Your cart has been automatically updated. There is no action required on your end.
            </li>
          </ul>
        </div>
      </FhAlert>
      <!-- UNAVAILALBE ITEMS MESSAGE -->
      <FhAlert v-if="unavailableItems.length > 0" icon="AlertCircle" class="mb-8 md:mb-f6">
        <div class="mb-2">The following items are no longer available. Please remove them before checking out.</div>
        <ul class="mb-2 ml-[1em] list-outside list-disc">
          <li v-for="item in unavailableItems" :key="item.sku.skuNumber">
            {{ item.sku.productName }} &bull; {{ item.sku.skuName }} &bull; {{ item.sku.skuNumber }}
          </li>
        </ul>
        <FhButton variant="link" @click="removeUnavailable">Remove All Unavailable Items</FhButton>
      </FhAlert>
      <!-- CUSTOM MESSAGE -->
      <FhAlert v-if="props.cartMessaging" icon="AlertCircle" class="mb-8 md:mb-f6">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="props.cartMessaging"></div>
      </FhAlert>
      <!-- WALLPAPER ITEMS MESSAGE -->
      <FhAlert v-if="hasWallpaperItems" icon="AlertCircle" class="mb-8 md:mb-f6">
        <div class="mb-2">Note: Wallpaper ships directly from our warehouse in Austin, TX, separate from other Four Hands products.</div>
      </FhAlert>
      <!-- CART ITEMS -->
      <TransitionGroup
        :leave-active-class="`transition ease-in duration-200 ${props.items.length > 0 ? '!absolute' : ''}`"
        leave-from-class="translate-x-0 opacity-100"
        leave-to-class="-translate-x-1/2 opacity-0"
        move-class="transtion duration-500"
        @after-enter="(el) => emit('itemEnter', el)"
        @before-leave="() => (isItemRemoveAnimationComplete = false)"
        @after-leave="() => (isItemRemoveAnimationComplete = true)"
      >
        <FhCartItem
          v-for="item in props.items"
          :key="item.key || item.id"
          v-bind="item"
          class="relative -mb-px scroll-mt-24"
          @update:quantity="(quantity) => emit('itemQuantityUpdate', { id: item.id, quantity })"
          @delete="() => emit('itemDelete', { id: item.id })"
          @saveforlater="() => emit('saveforlater', item)"
        />
      </TransitionGroup>
      <!-- EMPTY CART MESSAGE -->
      <Transition enter-active-class="ease-out duration-300 transition" enter-from-class="opacity-0" enter-to-class="opacity-100">
        <div v-if="isEmpty && isItemRemoveAnimationComplete" class="text-body-xl mb-f10 text-neutral-50">Your cart is empty.</div>
      </Transition>
      <!-- ERROR MESSAGE -->
      <div v-if="props.isError" class="text-body-xl mb-f10 flex items-center gap-f1">
        <FhIcon name="AlertCircle" />
        Sorry, we are not able to load your cart at this time.
      </div>
    </template>
    <template #summary>
      <FhCartSummary v-bind="cartSummaryProps">
        <template #actions>
          <FhButton color="primary" :disabled="isCheckoutDisabled" @click="checkout">Proceed to Checkout</FhButton>
        </template>
      </FhCartSummary>
    </template>
  </FhCartCheckoutLayout>
</template>
